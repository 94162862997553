import { ApplicationConfig, Injectable, isDevMode } from '@angular/core';
import { provideTransloco, provideTranslocoLoader, Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    getTranslation(lang: string) {
        return import('../assets/i18n/' + lang + '.json') as Promise<Translation>;
    }
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['en', 'nl', 'fr'],
                defaultLang: 'nl',
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
        }),
        provideTranslocoLoader(TranslocoHttpLoader),
    ],
};
