/* eslint-disable @typescript-eslint/no-empty-interface */
// noinspection JSUnusedGlobalSymbols

/**
 * This file is generated by `nx build interfaces`- do not edit manually.
 */

export enum Action {
    CREATE = 'create',
    DELETE = 'delete',
    MANAGE = 'manage',
    READ = 'read',
    UPDATE = 'update',
}

export enum ApiVersions {
    VERSION_ONE = '1',
}

export enum CollectorRequestStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum CollectorRequestType {
    COIN = 'COIN',
    ECONOMY = 'ECONOMY',
}

export enum CollectorStatus {
    DELETED = 'DELETED',
    REGISTERED = 'REGISTERED',
    REJECTED = 'REJECTED',
    REQUESTED = 'REQUESTED',
}

export enum DenominationLanguage {
    FR = 1,
    NL = 2,
    UNKNOWN = 0,
}

export enum DenominationType {
    ABBREVIATION = 2,
    COMMERCIAL_TRADE_NAME = 3,
    COMPANY_TRADE_NAME = 1,
    UNKNOWN = 0,
    UNKNOWN_2 = 4,
}

export enum EconomyStatus {
    ACTIVE = 'Active',
    CLOSED = 'Closed',
    CREATED = 'Created',
}

export enum ErrorKeyCoins {
    COIN_HAS_BALANCE = 'coin-has-balance',
}

export enum ErrorKeyEconomies {
    INVITATION_ONLY = 'economy-invitation-only',
    NOT_FOUND = 'economy-not-found',
}

export enum ErrorKeyEmails {
    ALREADY_IN_USE = 'email-already-in-use',
    NOT_FOUND = 'email-not-found',
    NOT_VERIFIED = 'email-not-verified',
}

export enum ErrorKeyIdpClients {
    NOT_FOUND = 'client-not-found',
}

export enum ErrorKeyIdpOobCodes {
    INCORRECT = 'oob-code-incorrect',
    NOT_FOUND = 'oob-code-not-found',
}

export enum ErrorKeyIdpTokens {
    NOT_FOUND = 'token-not-found',
}

export enum ErrorKeyInvitations {
    NOT_FOUND = 'invitation-not-found',
}

export enum ErrorKeyIp {
    UNKNOWN = 'ip-unknown',
}

export enum ErrorKeyPasswords {
    INCORRECT = 'password-incorrect',
    NOT_SET = 'password-not-set',
}

export enum ErrorKeyRateLimit {
    REACHED = 'rate-limit-reached',
}

export enum ErrorKeyTooManyRequests {
    DEFAULT = 'too-many-requests',
}

export enum ErrorKeyUsers {
    NOT_FOUND = 'user-not-found',
}

export enum ErrorKeyValidations {
    ERROR = 'validation-error',
}

export enum ErrorKeyVouchers {
    NOT_FOUND = 'voucher-not-found',
}

export enum FundVoucherStatus {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
}

export enum GetAdvertiserRequestSortField {
    NAME = 'name',
}

export enum GetBusinessRequestSortField {
    EMAILS = 'emails',
    NAME = 'name',
    VAT = 'vat',
}

export enum GetCampaignRequestSortField {
    FROM = 'from',
    NAME = 'name',
    UNTIL = 'until',
}

export enum GetCollectorInvitationRequestSortField {
    CREATED_AT = 'createdAt',
    EMAIL = 'email',
    UPDATED_AT = 'updatedAt',
}

export enum GetCollectorRequestSortField {
    STORE_NAME = 'storeName',
}

export enum GetEconomyRequestSortField {
    ACCOUNT = 'account',
    NAME = 'name',
    STATUS = 'status',
}

export enum GetFundDepositRequestOverviewRequestSortField {
    CREATED_AT = 'createdAt',
}

export enum GetFundOverviewRequestSortField {
    ECONOMY = 'economy',
    FUND = 'fund',
    HOLDING_ACCOUNT = 'holdingAccount',
}

export enum GetFundVoucherSortField {
    CREATED_AT = 'createdAt',
}

export enum GetSpenderInvitationRequestSortField {
    EMAIL = 'email',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
}

export enum GetSpenderRequestSortField {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
}

export enum GetUserRequestSortField {
    EMAILS = 'emails',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
}

export enum GetVerticalRequestSortField {
    NAME = 'name',
}

export enum HttpContentTypes {
    MULTI_PART_FORM_DATA = 'multipart/form-data',
    X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
}

export enum HttpHeaderNames {
    ACCEPT_LANGUAGE = 'Accept-Language',
    AUTHORIZATION = 'Authorization',
    WALLET_APP_SESSION = 'Wallet-App-Session',
    X_API_KEY = 'x-api-key',
}

export enum InvitationType {
    COLLECTOR = 'COLLECTOR',
    SPENDER = 'SPENDER',
    USER_GROUP = 'USER_GROUP',
}

export enum MediaOriginType {
    ADVERTISER = 'advertiser',
    BUSINESS = 'business',
    ECONOMY = 'economy',
    STORE = 'store',
    VERTICAL = 'vertical',
}

export enum MultiPartBodyKeysDto {
    BANNER = 'banner',
    BG_IMAGES = 'bgImages',
    FILE = 'file',
    ICON = 'icon',
    JSON_DATA = 'jsonData',
    LOGO = 'logo',
}

export enum OobRequestType {
    EMAIL_SIGNIN = 'EMAIL_SIGNIN',
    PASSWORD_RESET = 'PASSWORD_RESET',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export enum OrderDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum PhysicalPaymentRequestStatus {
    ABORTED = 'ABORTED',
    CONFIRMED = 'CONFIRMED',
    CREATED = 'CREATED',
    FAILED = 'FAILED',
    TIMEOUT = 'TIMEOUT',
}

export enum PushNotificationAction {
    OPEN_INVITATIONS = 'openInvitations',
    OPEN_WALLET = 'openWallet',
}

export enum QrCodePaymentRequestStatus {
    ABORTED = 'ABORTED',
    CONFIRMED = 'CONFIRMED',
    CREATED = 'CREATED',
    FAILED = 'FAILED',
    SCANNED = 'SCANNED',
    TIMEOUT = 'TIMEOUT',
}

export enum RequestForPaymentStatus {
    EXECUTED = 'Executed',
    FAILED = 'Failed',
    OPEN = 'Open',
    PARTIALLY_EXECUTED = 'Partially Executed',
}

export enum RoleName {
    BUSINESS_ADMIN = 'BUSINESS_ADMIN',
    CIRKLO = 'CIRKLO',
    ECONOMY_ADMIN = 'ECONOMY_ADMIN',
    SPENDER = 'SPENDER',
    SPENDER_ADMIN = 'SPENDER_ADMIN',
    STORE_ADMIN = 'STORE_ADMIN',
    STORE_EMPLOYEE = 'STORE_EMPLOYEE',
    VERTICAL_ECONOMY_ADMIN = 'VERTICAL_ECONOMY_ADMIN',
}

export enum SessionPlatform {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    WEB = 'WEB',
}

export enum SpenderStatus {
    DELETED = 'Deleted',
    REGISTERED = 'Registered',
}

export enum StorePayoutInterval {
    EVERY_2_WEEKS = '2 weeks',
}

export enum SupportedLanguage {
    EN = 'en',
    FR = 'fr',
    NL = 'nl',
}

export enum TicketPriority {
    High = 3,
    Low = 1,
    Medium = 2,
    Urgent = 4,
}

export enum TicketSource {
    Chat = 7,
    Email = 1,
    Feedback_Widget = 9,
    Outbound_Email = 10,
    Phone = 3,
    Portal = 2,
}

export enum TicketStatus {
    Closed = 5,
    Open = 2,
    Pending = 3,
    Resolved = 4,
}

export enum TransactionDirection {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
}

export enum TransactionType {
    CREDIT = 'CREDIT',
    DEBET = 'DEBET',
    EARN = 'EARN',
    REFUND = 'REFUND',
    SPEND = 'SPEND',
    TRANSFER = 'TRANSFER',
}

export enum TwikeyRefundStatus {
    CREATED = 'CREATED',
    EXECUTED = 'EXECUTED',
    SCHEDULED = 'SCHEDULED',
}

export enum VKBOEnterpriseStatus {
    ACTIVE = 1,
    INACTIVE = 2,
}

export enum VkboEnterpriseType {
    LEGAL_ENTITY = 2,
    NATURAL_PERSON = 1,
}

export enum WalletAppListEconomiesRequestSortField {
    DISTANCE = 'distance',
    NAME = 'name',
}

export enum WalletAppPaymentsReceiverKind {
    SPENDER = 'SPENDER',
    STORE = 'STORE',
}

export enum WebhookEvent {
    STORE_CREATE = 'store/create',
    STORE_DELETE = 'store/delete',
    STORE_UPDATE = 'store/update',
}

export enum WebSocketActionType {
    INVITES_UPDATED = '[WebSocket] Invites Updated',
    PHYSICAL_PAYMENT_REQUEST_RECEIVER_UPDATE = '[WebSocket] Physical Payment Request Receiver Update',
    QRCODE_PAYMENT_REQUEST_RECEIVER_UPDATE = '[WebSocket] QrCode Payment Request Receiver Update',
    QRCODE_PAYMENT_REQUEST_SENDER_UPDATE = '[WebSocket] QrCode Payment Request Sender Update',
    TEST = '[WebSocket] Test',
    USER_UPDATED = '[WebSocket] User Updated',
}

export enum WeekDay {
    FRIDAY = 5,
    MONDAY = 1,
    SATURDAY = 6,
    SUNDAY = 0,
    THURSDAY = 4,
    TUESDAY = 2,
    WEDNESDAY = 3,
}

export enum WhitelistingStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    REQUESTED = 'REQUESTED',
}

export interface AcceptInvitationDto {
    storeId?: string;
}

export interface AddBeneficiaryDto {
    address: string;
    city: string;
    country: string;
    customerNumber: string;
    iban: string;
}

export interface AddVoucherResultDto {
    spenderId: string;
}

export interface ApiKeyRoleDto {
    objectIds: string[];
    roleName: RoleName;
}

export interface AuditLogIdAndContextDto {
    context?: { [key: string]: unknown };
    id: string;
}

export interface AvailableRolesDto {
    business: RoleName[];
    economy: RoleName[];
    spender: RoleName[];
    store: RoleName[];
    vertical: RoleName[];
}

export interface BaseCurrencyDto {
    exchangeable: boolean;
    id: string;
    name: string;
    sign: string;
}

export interface BulkCreateCollectorInvitationDto {
    description?: string;
    emails: string[];
}

export interface BulkCreateStoresDto {
    stores: CreateStoreDto[];
}

export interface BulkCreateStoresResultDto {
    storeIds: string[];
}

export interface CoinDto {
    baseCurrency: BaseCurrencyDto;
    conversionRate: string;
    icon: GetMediaDto | null;
    id: string;
    name: string;
}

export interface CoinWithAmountDto {
    amountInCoins: number;
    coin: CoinDto;
}

export interface CoinWithBalanceDto {
    balanceInCoins: number;
    coin: CoinDto;
}

export interface CoinWithTranslationsDto extends CoinDto {
    nameTranslations: TranslationsDto;
}

export interface CollectorOverviewFilterDto extends OrderSortAndPaginatableRequestDto {
    city: string;
    coinIds: string[];
    placeTypes: string[];
    sortBy: GetCollectorRequestSortField;
    status: CollectorStatus;
    street: string;
    zipCode: string;
}

export interface CollectorRequestsFilterDto {
    type?: CollectorRequestType;
}

export interface ConfirmCollectorRequestsDto {
    ids: string[];
}

export interface ConfirmFundDepositRequestDto {
    amountInCoins: number;
    fromIban: string;
}

export interface ConfirmQrCodePaymentRequestDto {
    parts: WalletPartDto[];
    spenderId: string;
}

export interface ConfirmQrCodePaymentResponseDto {
    confirmedAt: string /* Date */;
    id: string;
    status: QrCodePaymentRequestStatus;
}

export interface CreateAddressDto {
    bus: string | null;
    countryCode: string;
    houseNumber: string;
    lat: number;
    lng: number;
    municipality: string;
    street: string;
    subMunicipality: string | null;
    zipCode: string;
}

export interface CreateAdvertiserBankAccountDto {
    iban: string;
    name: string;
}

export interface CreateAdvertiserDto {
    contactEmail: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhoneNumber: string;
    description?: string;
    global: boolean;
    name: string;
    websiteUrl: string;
}

export interface CreateApiKeyDto {
    name: string;
    roles: ApiKeyRoleDto[];
}

export interface CreateBankAccountDto {
    iban: string;
    name: string;
}

export interface CreateBaseCurrencyDto {
    exchangeable: boolean;
    name: string;
    sign: string;
}

export interface CreateBusinessDto {
    address: CreateAddressDto;
    emails: EmailDto[];
    legalForm: number | null;
    name: string;
    phoneNumbers: PhoneNumberDto[];
    vat: string | null;
    websites: WebsiteDto[];
}

export interface CreateCampaignDto {
    coinId: string;
    colorPrimary?: string;
    colorPrimaryText?: string;
    colorSecondary?: string;
    description: string;
    from?: string /* Date */;
    fundId?: string | null;
    isOnInvitationOnly: boolean;
    name: string;
    until?: string /* Date */;
    verticalId?: string | null;
}

export interface CreateCollectorDto {
    status: CollectorStatus;
    storeId: string;
}

export interface CreateCollectorInvitationDto {
    description?: string;
    email: string;
    expiresAt?: string /* Date */;
}

export interface CreateCollectorRedemptionDto {
    amountInCoins: number;
    coinId: string;
    fromCollectorId: string;
    note: string;
    reference: string;
    toBusinessId: string;
    toIban: string;
}

export interface CreateEconomyCoinDto {
    baseCurrencyId: string;
    conversionRate: string;
    iconId?: string;
    name: string;
    nameTranslations: TranslationsDto;
}

export interface CreateEconomyDto {
    backgroundMediaIds?: string[];
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    isOnInvitationOnly: boolean;
    location?: CreateEconomyLocationDto;
    name: string;
    rssFeedUrl?: string | null;
}

export interface CreateEconomyLocationDto {
    country: string;
    location: MapLocationDto;
    postalCodes: string[];
    provinces?: ProvinceDto[];
}

export interface CreateFundAdvertiserLinkDto {
    advertiserId: string;
}

export interface CreateFundDepositRequestDto {
    amountInCoins: number;
    coinId: string;
    fromIban: string;
    fundId: string;
    note?: string;
    reference?: string;
    sendToEmail: string;
    toFundIban: string;
}

export interface CreateFundDto {
    baseCurrencyId: string;
    fundDepositRequestsEmail: string;
    holdingAccount: CreateHoldingAccountDto;
    name: string;
}

export interface CreateFundVouchersDto {
    amount: number;
    amountInCoins: number;
    coinId: string;
    expiresAt: string /* Date */;
    note: string;
    reference: string;
}

export interface CreateFundVouchersResultDto {
    id: string;
    url: string;
    voucherIds: string[];
}

export interface CreateHoldingAccountDto {
    iban: string;
    name: string;
}

export interface CreateInvitationDto {
    email: string;
    firstName: string;
    lastName: string;
    roles: RoleName[];
}

export interface CreateOpeningHoursDto {
    exceptionalHours: ExceptionalHoursDto[];
    periods: OpeningPeriodDto[];
}

export interface CreateQrCodePaymentRequestBaseContextDto {
    kind: WalletAppPaymentsReceiverKind.STORE | WalletAppPaymentsReceiverKind.SPENDER;
}

export type CreateQrCodePaymentRequestContextDto =
    | CreateQrCodePaymentRequestSpenderContextDto
    | CreateQrCodePaymentRequestStoreContextDto;

export interface CreateQrCodePaymentRequestDto {
    amountInBaseCurrency: string;
    baseCurrencyId: string;
    context: CreateQrCodePaymentRequestContextDto;
}

export interface CreateQrCodePaymentRequestSpenderContextDto extends CreateQrCodePaymentRequestBaseContextDto {
    kind: WalletAppPaymentsReceiverKind.SPENDER;
    note: string | null;
    spenderId: string;
}

export interface CreateQrCodePaymentRequestStoreContextDto extends CreateQrCodePaymentRequestBaseContextDto {
    kind: WalletAppPaymentsReceiverKind.STORE;
    reference: string | null;
}

export interface CreateRefundTransferDto {
    amount: number;
    customerNumber: string;
    message: string;
    ref: string;
}

export interface CreateSpenderInvitationDto {
    email: string;
    firstName: string;
    lastName: string;
}

export interface CreateStoreDto {
    address: CreateAddressDto;
    branchNumber?: string | null;
    description: string;
    emails: EmailDto[];
    iban: string;
    keywords: string[];
    name: string;
    openingHours?: CreateOpeningHoursDto | null;
    payoutInterval?: StorePayoutInterval;
    payoutMessage?: string;
    phoneNumbers: PhoneNumberDto[];
    placeTypes: string[];
    websites: WebsiteDto[];
}

export interface CreateSupportTicketDto {
    description: string;
    email: string;
    subject: string;
}

export interface CreateTransactionsExportDto {
    campaignId?: string;
    email: string;
    from?: string /* Date */;
    language?: SupportedLanguage;
    searchString?: string;
    sortOrder?: OrderDirection;
    to?: string /* Date */;
    transactionType?: TransactionType;
    walletId?: string;
}

export interface CreateVerticalDto {
    appStoreLink?: string | null;
    companyEmail: string;
    companyName: string;
    companyUrl: string;
    description: string;
    name: string;
    playStoreLink?: string | null;
}

export interface CreateWebhookDto {
    callbackUrl: string;
    events: WebhookEvent[];
    secret: string;
}

export interface DeleteFundVoucherDto {
    note: string;
    reference: string;
}

export interface DisableBeneficiaryDto {
    customerNumber: string;
    iban: string;
}

export interface EconomyCampaignInfoDto extends WalletAppCampaignInfoDto {
    economy: GetEconomyDto;
}

export interface EconomyStoreDetailsDto extends EconomyStoreDto {
    banner: GetMediaDto | null;
    description: string;
    emails: EmailDto[];
    logo: GetMediaDto | null;
    phoneNumbers: PhoneNumberDto[];
    websites: WebsiteDto[];
}

export interface EconomyStoreDto extends EconomyStoreNameDto {
    coinIds: string[];
    openingHours: GetOpeningHoursDto | null;
    placeTypes: string[];
}

export interface EconomyStoreNameDto {
    address: GetAddressDto;
    businessId: string;
    id: string;
    name: string;
}

export interface EmailDto extends PrimaryValueDto {
    value: string;
}

export interface ExceptionalHoursDto {
    description: OpeningHourDescriptionDto | null;
    endDate: string /* Date */;
    periods: OpeningPeriodDto[];
    startDate: string /* Date */;
}

export interface FreshdeskAttachmentDto {
    attachment_url: string;
    content_type: string;
    created_at: string;
    id: number;
    name: string;
    size: number;
    updated_at: string;
}

export interface FreshdeskContactDto {
    email: string;
    id: number;
    mobile: string | null;
    name: string;
    phone: string | null;
}

export interface FreshdeskConversationDto {
    attachments: FreshdeskAttachmentDto[];
    body: string;
    created_at: string;
    from_email: string;
    id: number;
    user: {
        name: string;
        email: string;
        phone: string | null;
    };
}

export interface FreshdeskTicketDetailsDto extends FreshdeskTicketDto {
    conversations: FreshdeskConversationDto[];
}

export interface FreshdeskTicketDto {
    attachments: FreshdeskAttachmentDto[];
    created_at: string;
    description: string;
    id: number;
    requester: FreshdeskContactDto;
    status: TicketStatus;
    subject: string;
    updated_at: string;
}

export interface FullFreshdeskConversationDto extends FreshdeskConversationDto {
    private: boolean;
    user_id: number;
}

export interface GetAddressDto {
    bus: string | null;
    countryCode: string;
    houseNumber: string;
    id?: string;
    lat: number;
    lng: number;
    municipality: string;
    street: string;
    subMunicipality: string | null;
    zipCode: string;
}

export interface GetAdvertiserAccountDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetAdvertiserDetailsDto {
    bankAccounts: GetAdvertiserAccountDto[];
    contactEmail: string;
    contactFirstName: string;
    contactLastName: string;
    contactPhoneNumber: string;
    description: string | null;
    economies: GetEconomyAdvertiserDto[];
    id: string;
    isGlobal: boolean;
    logo: GetMediaDto | null;
    name: string;
    websiteUrl: string;
}

export interface GetAdvertiserDto {
    bankAccounts: GetBankAccountDto[];
    contactEmail: string;
    description: string | null;
    economies: GetAdvertiserOverviewEconomyDto[];
    funds: GetAdvertiserOverviewFundDto[];
    id: string;
    isGlobal: boolean;
    logo: GetMediaDto | null;
    name: string;
    websiteUrl: string;
}

export interface GetAdvertiserOverviewEconomyDto {
    id: string;
    name: string;
}

export interface GetAdvertiserOverviewFundDto {
    economy: GetAdvertiserOverviewEconomyDto;
    id: string;
    name: string;
}

export interface GetAdvertiserPaginationDto extends PaginationDto<GetAdvertiserDto> {
    results: GetAdvertiserDto[];
}

export interface GetAdvertiserRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetAdvertiserRequestSortField;
}

export interface GetApiKeyDetailsDto {
    createdAt: string /* Date */;
    enabled: boolean;
    id: string;
    name: string;
    roles: ApiKeyRoleDto[];
    value: string;
}

export interface GetBankAccountDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetBusinessDto extends GetBusinessWithoutStoresDto {
    stores: GetBusinessStoreDto[];
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetBusinessOverviewDto {
    address: GetAddressDto;
    emails: EmailDto[];
    id: string;
    name: string;
    phoneNumbers: PhoneNumberDto[];
    userGroup: GetUserGroupWithMembersDto;
    vat: string | null;
}

export interface GetBusinessOverviewPaginationDto extends PaginationDto<GetBusinessOverviewDto> {
    results: GetBusinessOverviewDto[];
}

export interface GetBusinessRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetBusinessRequestSortField;
}

export interface GetBusinessStoreDto {
    address: GetAddressDto;
    branchNumber: string | null;
    emails: EmailDto[];
    iban: string;
    id: string;
    name: string;
    openingHours: GetOpeningHoursDto | null;
    phoneNumbers: PhoneNumberDto[];
}

export interface GetBusinessWithoutStoresDto {
    address: GetAddressDto;
    emails: EmailDto[];
    id: string;
    legalForm: number | null;
    name: string;
    phoneNumbers: PhoneNumberDto[];
    vat: string | null;
    websites: WebsiteDto[];
}

export interface GetCampaignDto {
    coin: CoinDto;
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    description: string;
    economyId: string;
    from?: string /* Date */ | null;
    fund: GetEconomyFundDto | null;
    id: string;
    isOnInvitationOnly: boolean;
    logo: GetMediaDto;
    name: string;
    until?: string /* Date */ | null;
    vertical: GetCampaignVerticalDto | null;
}

export interface GetCampaignOverviewDto {
    coin: CoinDto;
    economyId: string;
    from: string /* Date */ | null;
    fund: GetEconomyFundDto | null;
    id: string;
    isOnInvitationOnly: boolean;
    logo: GetMediaDto;
    name: string;
    until: string /* Date */ | null;
    vertical: GetCampaignVerticalDto | null;
}

export interface GetCampaignOverviewPaginationDto extends PaginationDto<GetCampaignOverviewDto> {
    results: GetCampaignOverviewDto[];
}

export interface GetCampaignRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetCampaignRequestSortField;
}

export interface GetCampaignVerticalDto {
    appStoreLink: string | null;
    id: string;
    logo: GetMediaDto | null;
    name: string;
    playStoreLink: string | null;
}

export interface GetCollectorBasicDto {
    id: string;
    status: CollectorStatus;
}

export interface GetCollectorDetailBusinessDto {
    id: string;
    name: string;
    vat: string | null;
}

export interface GetCollectorDetailDto {
    business: GetCollectorDetailBusinessDto;
    economyId: string;
    id: string;
    status: CollectorStatus;
    store: GetCollectorDetailStoreDto;
    wallet?: GetWalletDetailDto;
    whitelistedCoins: GetCollectorWhitelistingDto[];
}

export interface GetCollectorDetailStoreDto {
    businessId: string;
    id: string;
    name: string;
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetCollectorDto {
    economyId: string;
    id: string;
    status: CollectorStatus;
    store: GetCollectorStoreDto;
    whitelistedCoins: CoinDto[];
}

export interface GetCollectorInvitationDto {
    acceptedAt: string /* Date */ | null;
    createdAt: string /* Date */;
    description: string | null;
    economyId: string;
    email: string;
    expiresAt: string /* Date */;
    id: string;
    reRequestedAt: string /* Date */ | null;
    store?: GetCollectorInvitationStoreDto;
    updatedAt: string /* Date */;
}

export interface GetCollectorInvitationPaginationDto extends PaginationDto<GetCollectorInvitationDto> {
    results: GetCollectorInvitationDto[];
}

export interface GetCollectorInvitationRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetCollectorInvitationRequestSortField;
}

export interface GetCollectorInvitationStoreDto {
    id: string;
    name: string;
}

export interface GetCollectorPaginationDto extends PaginationDto<GetCollectorDto> {
    results: GetCollectorDto[];
}

export interface GetCollectorRedemptionDto {
    amountInCoins: number;
    coin: CoinDto;
    fromCollectorId: string;
    id: string;
    note: string;
    reference: string;
    status: RequestForPaymentStatus;
    structuredNote: string;
    toBusinessId: string;
    toIban: string;
    transaction: GetCollectorRedemptionTransactionDto;
}

export interface GetCollectorRedemptionTransactionDto {
    amountInCoins: number;
    coin: CoinDto;
    id: string;
    note: string;
    receiverIban: string;
    reference: string;
    senderIban: string;
}

export interface GetCollectorRequestCollectorDto {
    id: string;
    store: GetCollectorStoreDto;
}

export interface GetCollectorRequestsDto {
    collector: GetCollectorRequestCollectorDto;
    createdAt: string /* Date */;
    economy: GetEconomyDto;
    type: CollectorRequestType;
    updatedAt: string /* Date */;
    whitelistedCoin: GetCollectorRequestWhitelistedCoinDto;
}

export interface GetCollectorRequestWhitelistedCoinDto {
    coin: CoinDto;
    id: string;
    store: GetCollectorStoreDto;
}

export interface GetCollectorStoreDto {
    address: string;
    branchNumber: string | null;
    id: string;
    name: string;
    placeTypes: string[];
    userGroup: GetUserGroupWithMembersDto;
    vat: string | null;
}

export interface GetCollectorWhitelistingDto {
    coin: CoinDto;
    status: WhitelistingStatus;
}

export interface GetEconomyAdvertiserDto {
    advertiserId: string;
    advertiserName: string;
    economy: GetEconomyDto;
    id: string;
}

export interface GetEconomyAdvertiserListDto {
    id: string;
    logo: GetMediaDto | null;
    name: string;
}

export interface GetEconomyBasicDto {
    coins: CoinWithTranslationsDto[];
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    id: string;
    location: GetEconomyLocationDto | null;
    logo: GetMediaDto;
    name: string;
}

export interface GetEconomyDetailsDto extends GetEconomyBasicDto {
    bankAccounts: GetBankAccountDto[];
    bgImages: GetMediaDto[];
    isOnInvitationOnly: boolean;
    location: GetEconomyLocationDto | null;
    rssFeed: string | null;
    status: EconomyStatus;
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetEconomyDto {
    id: string;
    logo: GetMediaDto;
    name: string;
}

export interface GetEconomyDtoPaginationDto extends PaginationDto<GetEconomyDto> {
    results: GetEconomyDto[];
}

export interface GetEconomyFundDto {
    fundDepositRequestsEmail: string | null;
    holdingAccount: GetFundHoldingAccountDto;
    id: string;
    name: string;
}

export interface GetEconomyLocationDto {
    country: string;
    mapLocation: MapLocationDto | null;
    postalCodes: string[];
    provinces: ProvinceDto[] | null;
}

export interface GetEconomyOverviewDto {
    coins: CoinDto[];
    id: string;
    logo: GetMediaDto;
    name: string;
    status: EconomyStatus;
    userEmails: string[];
}

export interface GetEconomyOverviewPaginationDto extends PaginationDto<GetEconomyOverviewDto> {
    results: GetEconomyOverviewDto[];
}

export interface GetEconomyRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetEconomyRequestSortField;
}

export interface GetEconomyThemeDto {
    bgImages: GetMediaDto[];
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    id: string;
    location: GetEconomyLocationDto | null;
    logo: GetMediaDto;
    name: string;
}

export interface GetEconomyUserGroupDto {
    invitations: GetUserGroupInvitationDto[];
    members: GetGroupMemberDto[];
}

export interface GetEconomyWithUsersDto {
    coins: CoinDto[];
    id: string;
    logo: GetMediaDto;
    name: string;
    userGroup: GetEconomyUserGroupDto;
}

export interface GetFundDepositRequestDto {
    amountInCoins: number;
    coin: CoinDto;
    economy: GetFundDepositRequestEconomyDto;
    executedAmountInCoins: number | null;
    fromIban: string;
    fund: GetFundDepositRequestFundDto;
    id: string;
    note: string | null;
    reference: string | null;
    status: RequestForPaymentStatus;
    structuredNote: string | null;
    transaction: GetFundDepositRequestTransactionDto;
}

export interface GetFundDepositRequestEconomyDto {
    id: string;
    name: string;
}

export interface GetFundDepositRequestFundDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetFundDepositRequestOverviewDto {
    amountInCoins: number;
    coin: CoinDto;
    createdAt: string /* Date */;
    economy: GetEconomyDto;
    executedAmountInCoins: number | null;
    executedFromIban: string | null;
    fromIban: string;
    fund: GetFundDepositRequestOverviewFundDto;
    id: string;
    sendToEmail: string;
    status: RequestForPaymentStatus;
    structuredNote: string | null;
    toFundIban: string;
}

export interface GetFundDepositRequestOverviewFundDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetFundDepositRequestOverviewPaginationDto extends PaginationDto<GetFundDepositRequestOverviewDto> {
    results: GetFundDepositRequestOverviewDto[];
}

export interface GetFundDepositRequestOverviewRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetFundDepositRequestOverviewRequestSortField;
    statusses?: RequestForPaymentStatus[];
}

export interface GetFundDepositRequestTransactionDto {
    amountInCoins: number;
    coin: CoinDto;
    id: string;
    note: string;
    receiverIban: string;
    reference: string;
    senderIban: string;
}

export interface GetFundDetailDto {
    advertisers: GetFundDetailFundAdvertiserDto[];
    baseCurrency: BaseCurrencyDto;
    economy: GetEconomyDto;
    economyId: string;
    fundDepositRequestsEmail: string | null;
    holdingAccount: GetFundDetailHoldingAccountDto;
    id: string;
    name: string;
    wallet: GetWalletDetailDto;
}

export interface GetFundDetailFundAdvertiserDto {
    advertiserId: string;
    contactEmail: string;
    id: string;
    logo: GetMediaDto | null;
    name: string;
}

export interface GetFundDetailHoldingAccountDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetFundHoldingAccountDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetFundOverviewAdvertiserDto {
    id: string;
    logo?: GetMediaDto;
    name: string;
}

export interface GetFundOverviewDto {
    advertisers: GetFundOverviewAdvertiserDto[];
    economy: GetEconomyDto;
    fundDepositRequestsEmail: string | null;
    holdingAccount: GetFundOverviewHoldingAccountDto;
    id: string;
    name: string;
    walletBalance: GetFundOverviewWalletBalanceDto | null;
}

export interface GetFundOverviewHoldingAccountDto {
    iban: string;
    id: string;
    name: string;
}

export interface GetFundOverviewPaginationDto extends PaginationDto<GetFundOverviewDto> {
    results: GetFundOverviewDto[];
}

export interface GetFundOverviewRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetFundOverviewRequestSortField;
}

export interface GetFundOverviewWalletBalanceDto {
    coins: CoinWithBalanceDto[];
    walletId: string;
}

export interface GetFundVoucherDto {
    createdAt: string /* Date */;
    id: string;
    qrCodeContent: string;
    status: FundVoucherStatus;
    wallet: GetWalletDetailDto;
}

export interface GetFundVoucherFilterDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetFundVoucherSortField;
}

export interface GetFundVoucherPaginationDto extends PaginationDto<GetFundVoucherDto> {
    results: GetFundVoucherDto[];
}

export interface GetGroupMemberDto {
    emails: UserEmailDto[];
    firstName: string;
    id: string;
    lastName: string;
    roles: string[];
    userId: string;
}

export interface GetMediaDto {
    fileName: string;
    id: string;
    url: string;
}

export type GetOpeningHoursDto = CreateOpeningHoursDto;

export interface GetPostalCodeDto {
    country: string;
    isSubMunicipality: boolean;
    location: MapLocationDto | null;
    municipality: string | null;
    name: string;
    postalCode: string;
    province: ProvinceDto;
    provinceId: string;
}

export interface GetRoleDto {
    name: RoleName;
    objectId: string;
}

export interface GetSpenderDetailDto {
    economyId: string;
    id: string;
    name: string;
    status: SpenderStatus;
    userGroup: GetUserGroupWithMembersDto;
    wallet: GetWalletDetailDto;
}

export interface GetSpenderDto {
    economyId: string;
    id: string;
    name: string;
    status: SpenderStatus;
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetSpenderInvitationDto {
    acceptedAt: string /* Date */ | null;
    createdAt: string /* Date */;
    economyId: string;
    email: string;
    expiresAt: string /* Date */;
    firstName: string;
    id: string;
    lastName: string;
    reRequestedAt: string /* Date */ | null;
}

export interface GetSpenderInvitationPaginationDto extends PaginationDto<GetSpenderInvitationDto> {
    results: GetSpenderInvitationDto[];
}

export interface GetSpenderInvitationRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetSpenderInvitationRequestSortField;
}

export interface GetSpenderPaginationDto extends PaginationDto<GetSpenderDto> {
    results: GetSpenderDto[];
}

export interface GetSpenderQueryDto {
    spenderIds: string[];
}

export interface GetSpenderRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetSpenderRequestSortField;
}

export interface GetStoreDto {
    address: GetAddressDto;
    banner: GetMediaDto | null;
    branchNumber: string | null;
    businessId: string;
    description: string;
    emails: EmailDto[];
    iban: string;
    id: string;
    keywords: string[];
    logo: GetMediaDto | null;
    name: string;
    nextPayoutAt: string /* Date */;
    openingHours: GetOpeningHoursDto | null;
    payoutInterval: StorePayoutInterval;
    payoutMessage: string | null;
    phoneNumbers: PhoneNumberDto[];
    placeTypes: string[];
    userGroup: GetUserGroupWithMembersDto;
    websites: WebsiteDto[];
}

export interface GetTransactionCampaigntDto {
    id: string;
    name: string;
}

export interface GetTransactionDto {
    campaign: GetTransactionCampaigntDto;
    createdAt: string /* Date */;
    economy: GetTransactionEconomyDto;
    id: string;
    parts: WalletAppTransactionPartDto[];
    receiver: GetTransactionPartyDto;
    sender: GetTransactionPartyDto;
    type: TransactionType;
}

export interface GetTransactionEconomyDto {
    id: string;
    logo: GetMediaDto;
    name: string;
}

export interface GetTransactionPaginationDto extends PaginationDto<GetTransactionDto> {
    results: GetTransactionDto[];
}

export interface GetTransactionPartDto {
    amountInCoins: number;
    coin: CoinDto;
    id: string;
}

export interface GetTransactionPartyDto {
    iban: string | null;
    name: string;
    walletId: string | null;
}

export interface GetTransactionRequestDto extends OrderSortAndPaginatableRequestDto {
    campaignId?: string;
    from?: string /* Date */;
    sortBy?: undefined;
    to?: string /* Date */;
    transactionType?: TransactionType;
    walletId?: string;
}

export interface GetUserDto {
    addresses: GetAddressDto[];
    emails: UserEmailDto[];
    firstName: string;
    id: string;
    isSuperAdmin: boolean;
    lastName: string;
    phoneNumbers: PhoneNumberDto[];
}

export interface GetUserGroupInvitationContextDto {
    id: string;
    logo: GetMediaDto | null;
    name: string;
}

export interface GetUserGroupInvitationDto {
    acceptedAt: string /* Date */ | null;
    createdAt: string /* Date */;
    email: string;
    expiresAt: string /* Date */;
    firstName: string;
    id: string;
    lastName: string;
    reRequestedAt: string /* Date */ | null;
    roles: RoleName[];
    userId: string | null;
}

export interface GetUserGroupWithMembersDto {
    id: string;
    invitations: GetUserGroupWithMembersInvitationDto[];
    name: string;
    users: GetUserGroupWithMembersUserDto[];
}

export interface GetUserGroupWithMembersInvitationDto {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    roles: RoleName[];
}

export interface GetUserGroupWithMembersUserDto {
    addresses: GetAddressDto[];
    emails: UserEmailDto[];
    firstName: string;
    id: string;
    lastName: string;
    phoneNumbers: string[];
    roles: RoleName[];
    userId: string;
}

export interface GetUserMembershipDto {
    businessId?: string;
    economyId?: string;
    groupName: string;
    id: string;
    roles: RoleName[];
    spenderId?: string;
    storeId?: string;
}

export interface GetUserPaginationDto extends PaginationDto<GetUserDto> {
    results: GetUserDto[];
}

export interface GetUserRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetUserRequestSortField;
}

export interface GetUserUserGroupInvitationDto {
    business: GetUserGroupInvitationContextDto | null;
    createdAt: string /* Date */;
    economy: GetUserGroupInvitationContextDto | null;
    expiresAt: string /* Date */;
    groupName: string;
    id: string;
    roles: RoleName[];
    store: GetUserGroupInvitationContextDto | null;
}

export interface GetUserWalletCollectorDto {
    id: string;
    store: GetUserWalletStoreDto;
}

export interface GetUserWalletDto {
    collector?: GetUserWalletCollectorDto;
    economy?: GetUserWalletEconomyDto;
    fund?: GetUserWalletFundDto;
    lastUpdated: string /* Date */;
    spender?: GetUserWalletSpenderDto;
    walletId: string;
    walletParts: GetUserWalletPartDto[];
}

export interface GetUserWalletEconomyDto {
    id: string;
    name: string;
}

export interface GetUserWalletFundDto {
    id: string;
    name: string;
}

export interface GetUserWalletPartDto {
    balanceInCoins: number;
    coin: CoinDto;
    walletPartId: string;
}

export interface GetUserWalletSpenderDto {
    id: string;
    name: string;
}

export interface GetUserWalletStoreDto {
    id: string;
    name: string;
}

export interface GetVerticalDetailsDto extends VerticalDto {
    apiKeys: GetApiKeyDetailsDto[];
    webhooks: GetWebhookDto[];
}

export interface GetVerticalRequestDto extends OrderSortAndPaginatableRequestDto {
    sortBy: GetVerticalRequestSortField;
}

export interface GetVkboDataByVatRequestDto {
    vat: string;
}

export interface GetWalletCollectorDto {
    id: string;
    status: CollectorStatus;
    store: GetWalletCollectorStoreDto;
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetWalletCollectorStoreDto {
    id: string;
    name: string;
}

export interface GetWalletDetailDto {
    id: string;
    lastUpdated: string /* Date */;
    walletParts: GetWalletDetailWalletPartDto[];
}

export interface GetWalletDetailWalletPartDto {
    balanceInCoins: number;
    coin: CoinDto;
    expiresAt: string /* Date */ | null;
    id: string;
    walletId: string;
}

export interface GetWalletDetailWithSourceDto extends GetWalletDetailDto {
    collector?: GetWalletCollectorDto;
    economy?: GetEconomyDto;
    fund?: GetWalletFundDto;
    spender?: GetWalletSpenderDto;
}

export interface GetWalletFundDto {
    id: string;
    name: string;
}

export interface GetWalletSpenderDto {
    id: string;
    name: string;
    status: SpenderStatus;
    userGroup: GetUserGroupWithMembersDto;
}

export interface GetWebhookDto {
    active: boolean;
    callbackUrl: string;
    events: WebhookEvent[];
    id: string;
    secret: string;
    verticalId: string;
}

export interface IPublicKeyDto {
    alg: string;
    e: string;
    kid: string;
    kty: string;
    n: string;
    use: string;
}

export interface LatLngBoundsDto {
    northeast: LatLngDto;
    southwest: LatLngDto;
}

export interface LatLngDto {
    lat: number;
    lng: number;
}

export interface LegalFormDto {
    abbreviation: string;
    id: number;
    name: string;
}

export interface LinkEconomyToAdvertiserDto {
    advertiserId: string;
}

export interface ListMembersDto {
    invitations: ListMembersInvitationDto[];
    members: ListMembersMemberDto[];
}

export interface ListMembersInvitationDto extends ListMembersMemberDto {
    expiresAt: string /* Date */;
}

export interface ListMembersMemberDto {
    createdAt: string /* Date */;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    roles: RoleName[];
}

export interface ListStoresParamsDto {
    campaignId?: string;
    placeTypes?: string[];
    searchString?: string;
}

export interface MapLocationDto {
    center: LatLngDto;
    viewport: LatLngBoundsDto | null;
}

export interface MediaOriginDto {
    objectId: string;
    objectType: MediaOriginType;
}

export interface OobCodeValueCodeDto {
    code: string;
}

export interface OpeningHourDescriptionDto {
    de?: string;
    en?: string;
    fr?: string;
    nl?: string;
}

export interface OpeningHourDto {
    day: WeekDay;
    time: string;
}

export interface OpeningPeriodDto {
    close: OpeningHourDto | null;
    description: OpeningHourDescriptionDto | null;
    open: OpeningHourDto;
}

export interface OpenInvitationsPushDataDto {
    readonly action: PushNotificationAction.OPEN_INVITATIONS;
}

export interface OpenWalletPushDataDto {
    readonly action: PushNotificationAction.OPEN_WALLET;
    readonly coinId: string;
    readonly spenderId: string;
}

export interface OrderSortAndPaginatableRequestDto {
    readonly page?: number;
    searchString?: string;
    readonly size?: number;
    readonly sortBy?: string;
    readonly sortOrder: OrderDirection;
}

export interface PaginationDto<PaginationEntity> {
    pageTotal: number;
    results: PaginationEntity[];
    total: number;
}

export interface PhoneNumberDto extends PrimaryValueDto {
    value: string;
}

export interface PhysicalPaymentRequestReceiverUpdateDto {
    id: string;
    status: PhysicalPaymentRequestStatus;
}

export interface PhysicalPaymentRequestTimeoutTaskPayloadDto {
    id: string;
}

export interface PlaceTypeDetailsDto {
    color: string;
    icon: string;
    id: string;
    title: string;
}

export interface PrimaryValueDto {
    name: string;
    primary?: boolean;
    value: string;
    verified?: boolean;
}

export interface ProvinceDto {
    country: string;
    id: string;
    translatedName: TranslatedNameDto;
}

export interface PublicKeyDto {
    alg: string;
    e: string;
    kid: string;
    kty: string;
    n: string;
    use: string;
}

export interface PublicKeysDto {
    keys: PublicKeyDto[];
}

export type PushNotificationDataDto = { [key: string]: string } & (OpenInvitationsPushDataDto | OpenWalletPushDataDto);

export interface QrCodePaymentRequestReceiverDto {
    amountInBaseCurrency: string;
    baseCurrency: BaseCurrencyDto;
    confirmedAt: string /* Date */ | null;
    id: string;
    qrCodeContent: string;
    status: QrCodePaymentRequestStatus;
}

export type QrCodePaymentRequestReceiverUpdateDto = Pick<QrCodePaymentRequestReceiverDto, 'id' | 'status'> &
    Partial<QrCodePaymentRequestReceiverDto>;

export interface QrCodePaymentRequestReceiverUserDto {
    firstName: string;
    lastName: string;
}

export interface QrCodePaymentRequestSenderDto {
    amountInBaseCurrency: string;
    baseCurrency: BaseCurrencyDto;
    confirmedAt: string /* Date */ | null;
    id: string;
    receiverCoins: WalletAppCoinDto[];
    receiverKind: WalletAppPaymentsReceiverKind.STORE | WalletAppPaymentsReceiverKind.SPENDER;
    receiverStore: WalletAppEconomyStoreNameDto | null;
    receiverUser: QrCodePaymentRequestReceiverUserDto;
    status: QrCodePaymentRequestStatus;
}

export type QrCodePaymentRequestSenderUpdateDto = Pick<QrCodePaymentRequestSenderDto, 'id' | 'status'> &
    Partial<QrCodePaymentRequestSenderDto>;

export interface QrCodePaymentRequestTimeoutTaskPayloadDto {
    id: string;
}

export interface RateLimitDto {
    expirationDates: Date[];
}

export interface ReclaimFundsRequestDto {
    coinId: string;
    expiredBefore: string /* Date */;
    note: string;
    reference: string;
}

export interface RefreshTokenDto {
    clientId: string;
    token: string;
}

export interface RefreshTokenResultDto {
    accessToken: string;
    refreshToken: string;
}

export interface RequestPayoutStoreDto {
    nextPayoutAt: string /* Date */;
}

export interface ResetPasswordDto {
    clientId: string;
    email: string;
    oobCode: string;
    password: string;
}

export interface RevokeTokenDto {
    clientId: string;
    token: string;
}

export interface SendOobCodeDto {
    clientId: string;
    email: string;
    requestType: OobRequestType;
    url: string;
}

export interface SendOobCodeResultDto {
    length: number;
}

export interface SignInResultDto {
    accessToken: string;
    idToken: string;
    refreshToken: string;
}

export interface SignInWithEmailDto {
    clientId: string;
    email: string;
    oobCode: string;
}

export interface SignInWithPasswordDto {
    clientId: string;
    email: string;
    password: string;
}

export interface SignUpAnonymouslyDto {
    clientId: string;
    userAttributes: SignUpAnonymouslyUserAttributesDto;
}

export interface SignUpAnonymouslyResultDto {
    id: string;
    tokens: SignInResultDto;
}

export interface SignUpAnonymouslyUserAttributesDto {
    language: SupportedLanguage;
}

export interface SignupUserAttributesDto {
    firstName: string;
    language: SupportedLanguage;
    lastName: string;
}

export interface SignupUserDto {
    clientId: string;
    email: string;
    password?: string;
    url?: string;
    userAttributes: SignupUserAttributesDto;
}

export interface SignupUserResultDto {
    codeLength: number;
    id: string;
}

export interface SupportAgentDto {
    email: string;
    id: number;
    name: string;
    phone: string | null;
}

export interface TransferBetweenFundsRequestDto {
    amountInCoins: number;
    coinId: string;
    toFundId: string;
}

export interface TranslatedNameDto {
    name: string;
    translations: TranslationsDto;
}

export interface TranslationsDto {
    [SupportedLanguage.EN]?: string;
    [SupportedLanguage.FR]?: string;
    [SupportedLanguage.NL]?: string;
}

export interface UpdateAddressDto extends CreateAddressDto {
    id?: string;
}

export interface UpdateAdvertiserDto {
    contactEmail?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhoneNumber?: string;
    description?: string;
    global?: boolean;
    name?: string;
    websiteUrl?: string;
}

export interface UpdateApiKeyDto {
    enabled?: boolean;
    name?: string;
    roles?: ApiKeyRoleDto[];
}

export interface UpdateBankAccountDto {
    iban: string;
    name: string;
}

export interface UpdateBusinessDto {
    address?: UpdateAddressDto;
    emails?: EmailDto[];
    legalForm?: number | null;
    name?: string;
    phoneNumbers?: PhoneNumberDto[];
    vat?: string | null;
    websites?: WebsiteDto[];
}

export interface UpdateCampaignDto {
    colorPrimary?: string;
    colorPrimaryText?: string;
    colorSecondary?: string;
    description?: string;
    from?: string /* Date */ | null;
    isOnInvitationOnly?: boolean;
    name?: string;
    until?: string /* Date */ | null;
    verticalId?: string;
}

export interface UpdateCollectorRedemptionDto {
    amountInCoins: number;
    coinId: string;
    note: string;
    reference: string;
}

export interface UpdateCollectorRequestDto {
    id: string;
    status: CollectorRequestStatus;
    type: CollectorRequestType;
}

export interface UpdateEconomyCoinDto {
    iconId?: string;
    name?: string;
    nameTranslations?: TranslationsDto;
}

export interface UpdateEconomyDto {
    colorPrimary?: string;
    colorPrimaryText?: string;
    colorSecondary?: string;
    isOnInvitationOnly?: boolean;
    location?: UpdateEconomyLocationDto;
    mediaBgImageIds?: string[];
    name?: string;
    rssFeed?: string | null;
}

export interface UpdateEconomyLocationDto {
    country?: string;
    location?: MapLocationDto;
    postalCodes?: string[];
    provinces?: ProvinceDto[];
}

export interface UpdateFundDto {
    fundDepositRequestsEmail?: string;
    holdingAccount?: UpdateHoldingAccountDto;
    name?: string;
}

export interface UpdateHoldingAccountDto {
    iban: string;
    name: string;
}

export interface UpdateMemberDto {
    roles: RoleName[];
}

export interface UpdateStoreDto {
    address?: UpdateAddressDto;
    branchNumber?: string | null;
    description?: string;
    emails?: EmailDto[];
    iban?: string;
    keywords?: string[];
    name?: string;
    openingHours?: CreateOpeningHoursDto | null;
    payoutInterval?: StorePayoutInterval;
    payoutMessage?: string;
    phoneNumbers?: PhoneNumberDto[];
    placeTypes?: string[];
    websites?: WebsiteDto[];
}

export interface UpdateUserDto {
    addresses?: UpdateAddressDto[];
    emails?: UpdateUserEmailDto[];
    firstName?: string;
    lastName?: string;
    phoneNumbers?: UpdateUserPhoneNumberDto[];
}

export interface UpdateUserEmailDto {
    name: string;
    primary?: boolean;
    value: string;
    verified?: boolean;
}

export interface UpdateUserPhoneNumberDto {
    name: string;
    primary?: boolean;
    value: string;
}

export interface UpdateVerticalDto {
    appStoreLink?: string | null;
    companyEmail?: string;
    companyName?: string;
    companyUrl?: string;
    description?: string;
    name?: string;
    playStoreLink?: string | null;
}

export interface UpdateWebhookDto {
    active?: boolean;
    callbackUrl?: string;
    events?: WebhookEvent[];
    secret?: string;
}

export interface UpdateWhitelistingDto {
    coinIds: string[];
    collectorIds: string[];
    status: WhitelistingStatus;
}

export interface UserEmailDto {
    id: string;
    verified?: boolean;
}

export interface VerifyEmailDto {
    clientId: string;
    email: string;
    oobCode: string;
}

export interface VerticalDto {
    appStoreLink: string | null;
    companyEmail: string;
    companyName: string;
    companyUrl: string;
    description: string;
    id: string;
    logo: GetMediaDto | null;
    name: string;
    playStoreLink: string | null;
}

export interface VkboAddressDto {
    box: string;
    country: string;
    dateStrikingOff: string /* Date */ | null;
    extraAddressInfo: string;
    houseNumber: string;
    municipalityFr: string;
    municipalityNl: string;
    streetFr: string;
    streetNl: string;
    type: 'REGO' | 'BAET' | 'ABBR';
    zipCode: string;
}

export interface VkboDenominationDto {
    denomination: string;
    language: DenominationLanguage;
    type: DenominationType;
}

export interface VkboEnterpriseDto {
    address: VkboAddressDto | null;
    denominations: VkboDenominationDto[];
    emails: string[];
    establishments: VkboEstablishmentDto[];
    id: string;
    juridicalForm: string | null;
    juridicalFormCac: string | null;
    juridicalSituation: string;
    phoneNumbers: string[];
    status: VKBOEnterpriseStatus;
    type: VkboEnterpriseType;
    websites: string[];
}

export interface VkboEstablishmentDto {
    address: VkboAddressDto;
    denominations: VkboDenominationDto[];
    enterpriseNumber: string;
    id: string;
    startDate: string /* Date */;
}

export interface WalletAppAdvertiserDto {
    description: string | null;
    logo: GetMediaDto | null;
    name: string;
}

export interface WalletAppAdvertiserWithAmountDto extends WalletAppAdvertiserDto {
    coins: CoinWithAmountDto[];
}

export interface WalletAppBusinessesListDto {
    id: string;
    name: string;
    permissions: WalletAppPermissionDto[];
    vat: string | null;
}

export interface WalletAppBusinessStoreDto {
    address: GetAddressDto;
    id: string;
    name: string;
    permissions: WalletAppPermissionDto[];
}

export interface WalletAppBusinessStoreFullDto {
    address: GetAddressDto;
    banner: GetMediaDto | null;
    branchNumber: string | null;
    businessId: string;
    description: string;
    emails: EmailDto[];
    iban: string;
    id: string;
    keywords: string[];
    logo: GetMediaDto | null;
    name: string;
    nextPayoutAt: string /* Date */;
    openingHours: GetOpeningHoursDto | null;
    payoutInterval: StorePayoutInterval;
    payoutMessage: string | null;
    permissions: WalletAppPermissionDto[];
    phoneNumbers: PhoneNumberDto[];
    placeTypes: string[];
    websites: WebsiteDto[];
}

export interface WalletAppCampaignDto extends WalletAppCampaignInfoDto {
    advertisers: WalletAppAdvertiserDto[];
    vertical: WalletAppCampaignVerticalDto | null;
}

export interface WalletAppCampaignInfoDto {
    coinId: string;
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    description: string;
    from: string /* Date */ | null;
    id: string;
    logo: GetMediaDto;
    name: string;
    until: string /* Date */ | null;
}

export interface WalletAppCampaignVerticalDto {
    appStoreLink: string | null;
    playStoreLink: string | null;
}

export interface WalletAppCoinDto {
    id: string;
    name: string;
}

export interface WalletAppCreateSessionDto {
    platform: SessionPlatform;
    pushToken: string | null;
}

export interface WalletAppCreateSpenderDto {
    economyId: string;
    name?: string | null;
}

export interface WalletAppCreateSpendersDto {
    spenders: WalletAppCreateSpenderDto[];
}

export interface WalletAppCreateTransactionsExportDto {
    economyId?: string;
    email: string;
    from?: string /* Date */;
    to?: string /* Date */;
}

export interface WalletAppEconomyCoinDto {
    coinId: string;
    status: WhitelistingStatus;
}

export interface WalletAppEconomyCollectorDto extends WalletAppEconomyDto {
    status: CollectorStatus;
}

export interface WalletAppEconomyDto {
    colorPrimary: string;
    colorPrimaryText: string;
    colorSecondary: string;
    distance: number | null;
    id: string;
    logo: GetMediaDto;
    name: string;
}

export interface WalletAppEconomyPaginationDto extends PaginationDto<WalletAppEconomyDto> {
    results: WalletAppEconomyDto[];
}

export interface WalletAppEconomyStoreDetailsDto extends WalletAppEconomyStoreDto {
    banner: GetMediaDto | null;
    coinIds: string[];
    description: string;
    emails: EmailDto[];
    logo: GetMediaDto | null;
    phoneNumbers: PhoneNumberDto[];
    websites: WebsiteDto[];
}

export interface WalletAppEconomyStoreDto extends WalletAppEconomyStoreNameDto {
    address: GetAddressDto;
    openingHours: GetOpeningHoursDto | null;
    placeTypes: string[];
}

export interface WalletAppEconomyStoreNameDto {
    address: GetAddressDto;
    businessId: string;
    id: string;
    name: string;
}

export interface WalletAppEconomyWithBgImageDto extends WalletAppEconomyDto {
    backgroundImage: GetMediaDto | null;
}

export interface WalletAppGetBusinessDto extends GetBusinessWithoutStoresDto {
    permissions: WalletAppPermissionDto[];
    stores: WalletAppBusinessStoreDto[];
}

export interface WalletAppInvitationDetailDto {
    id: string;
    name: string;
}

export interface WalletAppInvitationDto {
    business: WalletAppInvitationDetailDto | null;
    economy: WalletAppInvitationDetailDto | null;
    id: string;
    invitationType: InvitationType;
    sendAt: string /* Date */;
    spender: WalletAppInvitationDetailDto | null;
    store: WalletAppInvitationDetailDto | null;
}

export interface WalletAppInvitesUpdatedDto {
    readonly type: WebSocketActionType.INVITES_UPDATED;
}

export interface WalletAppListEconomiesQueryDto extends OrderSortAndPaginatableRequestDto {
    country?: string;
    lat?: number;
    lng?: number;
    postalCode?: string;
    sortBy: WalletAppListEconomiesRequestSortField;
}

export interface WalletAppNewsItemDto {
    description: string;
    imageUrl: string | null;
    link: string;
    pubDate: string;
    title: string;
}

export interface WalletAppPaymentsPhysicalConfirmDto {
    note: string | null;
    parts: WalletPartDto[];
    reference: string | null;
}

export interface WalletAppPaymentsPhysicalConfirmResultDto {
    confirmedAt: string /* Date */;
    id: string;
    status: PhysicalPaymentRequestStatus;
}

export interface WalletAppPaymentsPhysicalLookupDto {
    content: string;
}

export interface WalletAppPaymentsPhysicalLookupResultDto {
    id: string;
    wallet: WalletAppPaymentsPhysicalLookupResulWalletDto;
}

export interface WalletAppPaymentsPhysicalLookupResulWalletDto {
    lastUpdated: string /* Date */;
    parts: GetWalletDetailWalletPartDto[];
}

export interface WalletAppPaymentsTransferBaseReceiverDto {
    kind: WalletAppPaymentsReceiverKind.SPENDER | WalletAppPaymentsReceiverKind.STORE;
}

export interface WalletAppPaymentsTransferDto {
    note: string | null;
    parts: WalletPartDto[];
    receiver: WalletAppPaymentsTransferReceiverDto;
    senderSpenderId: string;
}

export type WalletAppPaymentsTransferReceiverDto =
    | WalletAppPaymentsTransferSpenderReceiverDto
    | WalletAppPaymentsTransferStoreReceiverDto;

export interface WalletAppPaymentsTransferSpenderReceiverDto extends WalletAppPaymentsTransferBaseReceiverDto {
    kind: WalletAppPaymentsReceiverKind.SPENDER;
    spenderId: string;
}

export interface WalletAppPaymentsTransferStoreReceiverDto extends WalletAppPaymentsTransferBaseReceiverDto {
    kind: WalletAppPaymentsReceiverKind.STORE;
    storeId: string;
}

export interface WalletAppPermissionDto {
    actions: Action[];
    subject: string;
}

export interface WalletAppPhysicalPaymentRequestReceiverUpdateDto {
    readonly request: PhysicalPaymentRequestReceiverUpdateDto;
    readonly type: WebSocketActionType.PHYSICAL_PAYMENT_REQUEST_RECEIVER_UPDATE;
}

export interface WalletAppQrCodePaymentRequestReceiverUpdateDto {
    readonly request: QrCodePaymentRequestReceiverUpdateDto;
    readonly type: WebSocketActionType.QRCODE_PAYMENT_REQUEST_RECEIVER_UPDATE;
}

export interface WalletAppQrCodePaymentRequestSenderUpdateDto {
    readonly request: QrCodePaymentRequestSenderUpdateDto;
    readonly type: WebSocketActionType.QRCODE_PAYMENT_REQUEST_SENDER_UPDATE;
}

export interface WalletAppRequestEconomyStoreDto {
    economyId: string;
}

export interface WalletAppRequestStoreEconomyCoinDto {
    coinId: string;
}

export interface WalletAppRequestStoreEconomyCoinResultDto {
    economy: WalletAppEconomyCollectorDto;
    status: WhitelistingStatus;
}

export interface WalletAppSessionDto {
    id: string;
    store: WalletAppStoreDto | null;
    user: WalletAppUserDto;
    websocketUrl: string;
}

export interface WalletAppSpenderDto {
    economyId: string;
    id: string;
    name: string;
    permissions: WalletAppPermissionDto[];
    walletItems: WalletAppSpenderWalletItemDto[];
}

export interface WalletAppSpenderWalletItemDto {
    coin: CoinDto;
    parts: WalletAppSpenderWalletPartDto[];
}

export interface WalletAppSpenderWalletPartDto {
    balanceInCoins: number;
    expiresAt: string /* Date */ | null;
    id: string;
}

export interface WalletAppStoreCollectorWalletDto {
    coins: CoinWithBalanceDto[];
    economyId: string;
}

export interface WalletAppStoreDto extends WalletAppEconomyStoreNameDto {
    logo: GetMediaDto | null;
    permissions: WalletAppPermissionDto[];
}

export interface WalletAppTestDto {
    readonly message: string;
    readonly type: WebSocketActionType.TEST;
}

export interface WalletAppTransactionDto {
    amountInBaseCurrency: string;
    baseCurrency: BaseCurrencyDto;
    createdAt: string /* Date */;
    direction: TransactionDirection;
    id: string;
    note: string | null;
    reference: string | null;
    type: TransactionType;
}

export interface WalletAppTransactionFilterDto {
    economyId?: string;
    from?: string /* Date */;
    page: number;
    size: number;
    to?: string /* Date */;
}

export interface WalletAppTransactionPaginationDto extends PaginationDto<WalletAppTransactionDto> {
    results: WalletAppTransactionDto[];
}

export interface WalletAppTransactionPartDto {
    amountInCoins: number;
    coin: CoinDto;
    id: string;
}

export interface WalletAppUpdateSessionProfileDto {
    storeId: string | null;
}

export interface WalletAppUpdateSessionPushTokenDto {
    pushToken: string;
}

export interface WalletAppUpdateSpenderDto {
    name: string;
}

export interface WalletAppUpdateUserDto {
    addresses: GetAddressDto[];
    firstName: string;
    language: SupportedLanguage;
    lastName: string;
    phoneNumbers: PhoneNumberDto[];
}

export interface WalletAppUserDto extends WalletAppUpdateUserDto {
    emails: UserEmailDto[];
    id: string;
}

export interface WalletAppUserUpdatedDto {
    readonly type: WebSocketActionType.USER_UPDATED;
    readonly user: WalletAppUserDto;
}

export interface WalletPartDto {
    amountOfCoins: number;
    id: string;
}

export interface WalletPartsExpirationReminderTaskPayloadDto {
    id: string;
}

export interface WalletStorePayoutSchedulerTaskPayloadDto {
    id: string;
}

export type WebhookEventPayloadDto = WebhookEventStorePayloadDto;

export interface WebhookEventStorePayloadDto {
    address: GetAddressDto;
    banner: GetMediaDto | null;
    branchNumber: string | null;
    businessId: string;
    description: string;
    emails: EmailDto[];
    iban: string;
    id: string;
    keywords: string[];
    logo: GetMediaDto | null;
    name: string;
    openingHours: GetOpeningHoursDto | null;
    phoneNumbers: PhoneNumberDto[];
    placeTypes: string[];
    websites: WebsiteDto[];
}

export interface WebhookEventTaskPayloadDto {
    callbackUrl: string;
    event: WebhookEvent;
    payload: WebhookEventPayloadDto;
    secret: string;
}

export interface WebsiteDto extends PrimaryValueDto {
    value: string;
}

export type WebSocketActions =
    | WalletAppInvitesUpdatedDto
    | WalletAppPhysicalPaymentRequestReceiverUpdateDto
    | WalletAppQrCodePaymentRequestReceiverUpdateDto
    | WalletAppQrCodePaymentRequestSenderUpdateDto
    | WalletAppTestDto
    | WalletAppUserUpdatedDto;
