<svg class="logo" viewBox="0 0 665.63 715.37">
    <path
        class="cls-1"
        d="M359.88,266.42c107.08-19.29,160.53-52.72,179.95-85.39,13.26-22.31,8.01-44.14-6.87-57.8-16.48-15.14-43.99-21.32-72.68-10.03-38.31,15.06-78.05,60.16-100.4,153.23h0ZM45.24,360.54l14.16,98.01c82.82-8.37,168.82-16.31,251.65-24.68l.14-116.56L45.24,360.54h0Zm-25,124.17L.2,345.93h.02c-1.63-11.42,6.18-22.09,17.61-23.94l200.39-32.57c-93.25-28.24-144.58-69.77-166.96-112.03-28.87-54.5-11.91-109.75,26.18-143.91C114.79-.05,172.79-12.95,227.19,16.16c45.54,24.37,88.29,78.93,111.69,177.1,27.92-67.77,67.27-104.45,105.94-119.65,45.04-17.71,89.47-6.87,116.91,18.35,29.05,26.68,39.71,68.58,14.69,110.69-19.33,32.52-61.91,65.15-137.53,88.42l209.48,40.56c11.29,2.16,18.77,12.88,17,24.15l-13.16,140.07c-1.05,11.66-11.37,20.26-23.03,19.21-.31-.03-.61-.07-.91-.1v.02s-21.46-2.92-21.46-2.92l-20.14,179.99c-1.28,11.66-11.79,20.07-23.45,18.79-11.66-1.28-20.08-11.78-18.79-23.45l20.26-181.07-210.78-28.74-.46,108.42c-.05,11.71-9.58,21.16-21.29,21.12-11.71-.04-21.16-9.58-21.12-21.28l.46-110.07-202.5,20.46,16.13,196.24c.92,11.71-7.83,21.95-19.54,22.86-11.71,.92-21.95-7.83-22.86-19.54l-16.05-195.29-22.25,2.25-.15,.02c-11.62,1.65-22.38-6.43-24.03-18.04h0Zm591.8-14.79l9.42-100.22-267.85-51.85-.15,116.81c87.14,11.88,172.11,23.46,258.59,35.26h0ZM88.83,157.59c23.01,43.44,87.98,86.58,219.97,109.41-15.31-125.97-56.97-189.56-101.58-213.43-36.99-19.8-76.29-11.15-101.53,11.48-24.5,21.98-35.43,57.51-16.87,92.53h0Z" />
    <path
        class="cls-2"
        d="M142.49,517.78l5.66,65.19,51.83-4.14-5.34-66.84-52.15,5.79h0Zm72.43,59.86l58.56-4.67-1.62-69.56-62.32,6.93,5.37,67.31h0Zm-65.48,20.26l4.92,56.76,52.01,4.29-5.2-65.17-51.73,4.13h0Zm72.07,62.29l54.1,4.46-1.78-76.69-57.72,4.61,5.4,67.62h0Z" />
    <path
        class="cls-2"
        d="M526.65,535.59l-6.38,73-58.47-9.56,6.02-74.9,58.83,11.46h0Zm-81.71,60.68l-66.06-10.8,1.83-78.31,70.3,13.7-6.06,75.42h0Zm73.87,29.04l-5.56,63.56-58.67-.07,5.87-73.03,58.35,9.54h0Zm-81.3,63.46l-61.03-.08,2.01-86.33,65.11,10.65-6.09,75.76h0Z" />
</svg>
<ng-container *transloco="let t">
    <ng-container *ngIf="isMobile; else notMobile">
        <a [href]="appLink" color="primary" mat-raised-button>
            {{ t('TAKE_ME_TO_THE_APP') }}
        </a>
    </ng-container>
    <ng-template #notMobile>
        <h3>{{ t('CONTINUE_WITH_YOUR_PHONE') }}</h3>
        <p>{{ t('CAN_ONLY_USE_MOBILE') }}</p>
    </ng-template>
</ng-container>
