import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DEFAULT_LANGUAGE, getSupportedLanguage } from '@icp/angular/i18n';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';

import { environment } from '../environments/environment';

@Component({
    standalone: true,
    selector: 'icp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [TranslocoDirective, NgIf, MatButtonModule, AsyncPipe],
})
export class AppComponent implements OnInit {
    appLink = `${environment.appHostName}://${window.location.pathname.slice(1)}${window.location.search}`;
    // For example icp://link/invitations/...
    isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    private transloco = inject(TranslocoService);

    ngOnInit() {
        this.transloco.setActiveLang(getSupportedLanguage(navigator.language) ?? DEFAULT_LANGUAGE);
    }
}
