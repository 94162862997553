import { SupportedLanguage } from '@icp/interfaces';

export const DEFAULT_LOCALE = 'en-GB';
// en-GB first to avoid US formatting in non US countries
export const SUPPORTED_LOCALES: string[] = ['en-GB', 'en-US', 'nl', 'fr'];
// Must match filenames of the files in assets/i18n for *all* embedded apps
export const SUPPORTED_LANGUAGES = Object.values(SupportedLanguage);
export const DEFAULT_LANGUAGE = SupportedLanguage.EN;

export function getSupportedLanguage(
    language: string,
    supportedLanguages = SUPPORTED_LANGUAGES,
): SupportedLanguage | null {
    language = language.replace('_', '-');
    const split = language.split('-');
    for (const lang of supportedLanguages) {
        if (lang.startsWith(language) || split[0] === lang) {
            return lang;
        }
    }
    return null;
}
